const planLookup = (team, priceConfig) => {
  if (!team) {
    return;
  }
  const enterprisePlan = team.enterprisePlan || null;

  if (!priceConfig) {
    return enterprisePlan;
  }
  const plan = priceConfig.find(
    (config) => config.enterprisePlan == enterprisePlan && config.isCurrent === true,
  );
  if (team.enterprisePlan == 30 || team.enterprisePlan == 20) {
    return "Legacy Teams";
  }

  if (team.enterprisePlan === "60") {
    return "New Billing";
  }

  if (!plan) return enterprisePlan;
  if (team.enterprisePlan == 50 && team.subscriptionTier) {
    return `${plan.title} - ${team.subscriptionTier}`;
  }
  return plan.title;
};

export default planLookup;
