const formatTrialEnd = (dateString) => {
  const yyyymmdd = dateString?.split("T")[0];
  return yyyymmdd;
};

const getDaysBetween = (startDate, endDate) => {
  const toEST = (date) => new Date(new Date(date).toLocaleString("en-US", { timeZone: "America/New_York" }));

  const start = toEST(startDate);
  const end = toEST(`${endDate}T00:00:00`);

  return Math.floor((end - start) / (1000 * 60 * 60 * 24)) + 1;
};

const dates = {
  formatTrialEnd,
  getDaysBetween,
};

export default dates;
