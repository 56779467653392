import React, { useContext, useEffect, useState } from "react";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputLabel from "@material-ui/core/InputLabel";
import {
  Button, styled, FormLabel, TextareaAutosize, Checkbox,
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeAddPaymentModal from "./StripeAddPaymentModal";
import MainContext from "./context/main/mainContext";
import DemoTeamSummary from "./DemoTeamSummary";
import ToolTip from "./ui/ToolTip";
import SelectField from "./ui/SelectField";
import countries from "./data/countries";
import dates from "./utilities/dates";
import states from "./data/states";

const leadCaptureTierOptions = [
  { label: "No Leads", value: 0 },
  { label: "1 - 100", value: 100 },
  { label: "101 - 500", value: 500 },
  { label: "501 - 1000", value: 1000 },
  { label: "1001 - 2500", value: 2500 },
  { label: "2501 - 5000", value: 5000 },
  { label: "5001 - 10000", value: 10000 },
  { label: "10001 - 25000", value: 25000 },
  { label: "25001 - 50000", value: 50000 },
  { label: "50000+", value: 50001 },
];

const CustomButton = styled(Button)(({ style }) => ({
  color: "#fff",
  backgroundColor: "#000",
  "&:hover": {
    backgroundColor: "#2D2D2D",
  },
  "&:disabled": {
    backgroundColor: "#9a9a9a60",
  },
  padding: "6px 0px",
  width: 170,
  textTransform: "none",
  fontSize: 13,
  fontWeight: 500,
  borderRadius: 8,
  fontFamily: "Inter, sans-serif",
  transition: "0.2s !important",
  ...style,
}));

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    height: 48,
  },
  extendedTextField: {
    width: 300,
  },
  inputSelect: {
    height: 56,
    padding: 5,
  },
  extendedTextField: {
    width: 300,
  },
}));

const InternalDemoTeamForm = () => {
  document.title = "Popl Internal Dash - Edit Subscription";

  const mainContext = useContext(MainContext);
  const classes = useStyles();
  const {
    team,
    stripe_customer,
    stripe_customer_payment_methods,
    getCustomerPaymentMethods,
    stripe_subscription_preview,
    stripe_subscription_preview_new_billing,
    previewStripeSubscriptionCurrentPricing,
    previewStripeSubscriptionNewBilling,
    previewStripeSubscriptionCustomPricing,
    createDemoTeam,
    setTeamPricing,
    downloadQuote,
    resetDemoTeamState,
    getPriceConfig,
    price_config,
    postDemoFlowStoredpriceId,
    postDemoFlowUpgrade,
    postDemoFlowStoredTeamId,
    postDemoFlowStoredTeamSize,
    postDemoTeamCreated,
    isProd,
    setDemoFlowDone,
  } = mainContext;

  const [validationErrors, setValidationErrors] = useState({});
  const internalDemoTeam = team?.team?.internalDemoTeams?.find(({ isDeleted }) => !isDeleted);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isCustomPricing, setIsCustomPricing] = useState(
    !!internalDemoTeam?.pricePerSeatDollars || !!internalDemoTeam?.bucketPercentOff,
  );
  // Inputs
  const [numberSeats, setNumberSeats] = useState(internalDemoTeam?.numberSeats || 5);
  const [leadsNumber, setLeadsNumber] = useState(team?.team?.leadsPaid || 0);

  const [leadsNumberInput, setLeadsNumberInput] = useState(0);
  const [companyName, setCompanyName] = useState(team?.team?.companyName || "");
  const [discountPercent, setDiscountPercent] = useState(0);
  const [chargeAutomatically, setChargeAutomatically] = useState(false);
  const [priceId, setPriceId] = useState(internalDemoTeam?.stripeSourcePriceId || "");
  const [isPriceMonthly, setIsPriceMonthly] = useState(false);
  const [offerFreeTrial, setOfferFreeTrial] = useState(
    internalDemoTeam?.trialExpiresAtutc || internalDemoTeam?.givenTrialWithoutCc,
  );

  const [addressInfo, setAddressInfo] = useState({
    country: stripe_customer?.address?.country || "US",
    zipcode: stripe_customer?.address?.postal_code || "",
    state: stripe_customer?.address?.state || "",
    addressLine: stripe_customer?.address?.line1 || "",
    city: stripe_customer?.address?.city || "",
  });

  const [isInvoiceMode, setIsInvioceMode] = useState(
    team?.stripeCustomer?.subscriptions?.data?.length > 0 && team?.team?.stripeSubscription,
  );

  const [customMemo, setCustomMemo] = useState(internalDemoTeam?.quotedCustomMemo || "");

  const defaultEndDate = new Date();
  defaultEndDate.setDate(defaultEndDate.getDate() + 15);

  const [trialDate, setTrialDate] = useState(
    dates.formatTrialEnd(internalDemoTeam?.trialExpiresAtUtc || defaultEndDate.toISOString()),
  );

  const [dueDate, setDueDate] = useState(
    dates.formatTrialEnd(internalDemoTeam?.dueDateUtc || defaultEndDate.toISOString()),
  );

  // Multi Year
  const [multiYearSub, setMultiYearSub] = useState(internalDemoTeam?.multiYearSub || false);
  const [multiYearSubYears, setMultiYearSubYears] = useState(
    internalDemoTeam?.multiYearSubYears || 1,
  );

  useEffect(() => {
    if (dates.getDaysBetween(new Date(), dueDate) < 0) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        dueDate: "Date must be in the future",
      }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, dueDate: "" }));
    }
  }, [dueDate]);

  useEffect(() => {
    if (offerFreeTrial && dates.getDaysBetween(new Date(), trialDate) < 0) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        trialDate: "Date must be in the future",
      }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, trialDate: "" }));
    }
  }, [trialDate, offerFreeTrial]);

  useEffect(() => {
    if (numberSeats < 5 || Number.isNaN(numberSeats)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        numberSeats: "Number of seats must be greater than 5",
      }));
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, numberSeats: "" }));
    }
  }, [numberSeats]);

  useEffect(() => {
    if (isInvoiceMode) {
      setOfferFreeTrial(false);
    }
  }, [isInvoiceMode]);

  useEffect(() => {
    const years = Number.parseInt(multiYearSubYears, 10);

    if (!multiYearSub) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        multiYearSubYears: "",
      }));

      setMultiYearSubYears(1);
      return;
    }

    if (years < 2 || Number.isNaN(years)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        multiYearSubYears: "Must choose a year greater than 1",
      }));
    } else {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        multiYearSubYears: "",
      }));
    }
  }, [multiYearSubYears, multiYearSub]);

  // Custom Only
  const [pricePerSeatDollars, setPricePerSeatDollars] = useState(
    internalDemoTeam?.pricePerSeatDollars || 0,
  );

  const [pricePerSeatDollarsChecked, setPricePerSeatDollarsChecked] = useState(
    !!internalDemoTeam?.pricePerSeatDollars || false,
  );

  const [customBucketDiscount, setCustomBucketDiscount] = useState(
    internalDemoTeam?.bucketPercentOff || 0,
  );

  const [customBucketDiscountChecked, setCustomBucketDiscountChecked] = useState(
    !!internalDemoTeam?.bucketPercentOff || false,
  );

  const switchPricingMode = (e) => {
    setIsCustomPricing(e.target.checked);
  };

  const handleAddressChange = (field, value) => {
    const maxLengths = {
      addressLine: 255,
      city: 140,
      zipcode: 10,
    };

    // Validate length if field has a max length constraint
    if (maxLengths[field] && value.length > maxLengths[field]) {
      return;
    }

    setAddressInfo((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Updated country and state handlers
  const handleSelectCountry = (event) => {
    handleAddressChange("country", event.target.value);
  };

  const handleSelectState = (event) => {
    handleAddressChange("state", event.target.value);
  };

  // Get price config
  useEffect(() => {
    if (!price_config) {
      getPriceConfig((err) => {
        if (err) {
          alert(err);
        }
      });
    }
  }, []);

  // Filter price config
  const [filteredPriceConfig, setFilteredPriceConfig] = useState([]);
  useEffect(() => {
    if (!price_config) return;

    const filtered = (price_config || []).filter(
      (config) => config.isCurrent && config.enterprisePlan !== null,
    );

    filtered.push({
      period: "year",
      priceId: "new_billing_price",
      isCurrent: true,
      enterprisePlan: 60,
      tierLevel: "",
      title: "New Billing",
      salesForceName: "New Billing Plan",
      code: "",
      displayName: "New Billing",
    });
    setFilteredPriceConfig(filtered);
    if (
      internalDemoTeam?.stripeSourcePriceId
      && !filtered.find(({ priceId }) => priceId === internalDemoTeam?.stripeSourcePriceId)
    ) {
      setPriceId("new_billing_price");
    }
  }, [price_config]);

  const doPreview = () => {
    // Clear out global state
    resetDemoTeamState();

    // Set loading to true
    setIsLoadingPreview(true);

    let fn = null;
    if (!isPriceMonthly && isCustomPricing) {
      fn = previewStripeSubscriptionCustomPricing;
    } else {
      fn = previewStripeSubscriptionCurrentPricing;
    }

    if (priceId === "new_billing_price") {
      fn = previewStripeSubscriptionNewBilling;
    }

    fn(
      {
        number_seats: numberSeats,
        price_per_seat_yearly_dollars:
          !isPriceMonthly && isCustomPricing && pricePerSeatDollarsChecked
            ? pricePerSeatDollars
            : null,

        discount_factor:
          !isPriceMonthly && isCustomPricing && customBucketDiscountChecked
            ? 1 - customBucketDiscount / 100
            : 1,
        leadsNumber,
        multiYearSubYears,
        sourcePriceId: postDemoFlowStoredpriceId || priceId,
      },
      (err) => {
        if (err) {
          alert(err);
        }
        setIsLoadingPreview(false);
      },
    );
  };

  const doCreate = () => {
    // Clear out global state
    resetDemoTeamState();

    // Set loading to true
    setIsLoading(true);

    // Convert YYYY-MM-DD to days from now
    let trialDays = dates.getDaysBetween(new Date(), trialDate || defaultEndDate);
    let daysUntilDue = dates.getDaysBetween(new Date(), dueDate || defaultEndDate);

    if (isPriceMonthly && offerFreeTrial) {
      trialDays = 30;
    }

    const payload = {
      // Details & Meta
      teamId: postDemoFlowStoredTeamId,
      stripeCustomerId: stripe_customer?.id,
      companyName,
      isUpgrade: postDemoFlowUpgrade,
      // Plan Info
      stripeSourcePriceId: postDemoFlowStoredpriceId || priceId,
      numberSeats: isPriceMonthly && offerFreeTrial ? null : numberSeats,
      leadsNumber: leadsNumber === 50001 ? leadsNumberInput : leadsNumber,
      chargeAutomatically,
      stripeAddress: addressInfo,
      // Pricing details
      customPricing:
        !isPriceMonthly
        && isCustomPricing
        && (pricePerSeatDollarsChecked || customBucketDiscountChecked),
      pricePerSeatDollars:
        !isPriceMonthly && isCustomPricing && pricePerSeatDollarsChecked
          ? pricePerSeatDollars
          : null,
      discountFactor: isPriceMonthly ? 1 : 1 - discountPercent / 100,
      // Trial Stuff
      trialDays: offerFreeTrial ? trialDays : 0,
      // MultiYear
      multiYearSub: !isPriceMonthly && multiYearSub,
      multiYearSubYears: !isPriceMonthly && multiYearSub ? multiYearSubYears : 1,
      teamOwnerName: stripe_customer?.name,
      daysUntilDue,
      customMemo,
      customBucketDiscountFactor:
        !isPriceMonthly && isCustomPricing && customBucketDiscountChecked
          ? 1 - customBucketDiscount / 100
          : 1,
    };

    const shouldCreateOrUpdateSub = (postDemoFlowUpgrade
        && team?.stripeCustomer?.subscriptions?.data?.length > 0
        && team?.team?.stripeSubscription)
      || isInvoiceMode;

    const fn = shouldCreateOrUpdateSub ? setTeamPricing : createDemoTeam;

    fn(payload, (err) => {
      setIsLoading(false);
    });
  };

  const handleDownloadQuote = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const data = {
      teamId: postDemoFlowStoredTeamId,
      includeCheckout: true,
      companyName,
    };

    await downloadQuote(data, (err) => {
      if (err) {
        alert(`Error download quote: ${err}`);
      }
      setIsLoading(false);
    });
  };

  const baseSubmit = () => {
    doPreview();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    baseSubmit();
  };

  const changeDiscountPercent = (e) => {
    let val = parseFloat(e.target.value, 10);

    if (val > 100) {
      val = 100;
    } else if (val < 0) {
      val = 0;
    }

    setDiscountPercent(val);
  };

  useEffect(() => {
    if (priceId) {
      const priceConfig = price_config.find((config) => config.priceId === priceId);
      if (priceConfig && priceConfig.period === "month") {
        setIsPriceMonthly(true);
        setOfferFreeTrial(false);
      } else {
        setIsPriceMonthly(false);
      }
    }
  }, [priceId]);
  // Add payment modal

  const [openStripePaymentModal, setOpenStripePaymentModal] = useState(false);
  const doOpenStripePaymentModal = () => {
    setOpenStripePaymentModal(true);
  };

  const onCloseStripePaymentModal = () => {
    setOpenStripePaymentModal(false);
  };

  const stripeOptions = {
    mode: "setup",
    currency: "usd",
    paymentMethodTypes: ["card"],
    disableLink: true,
  };

  const [stripePromise, setStripePromise] = useState(null);
  useEffect(() => {
    // These keys are public
    loadStripe(
      isProd
        ? "pk_live_51ISRsnJqkGKmOFO6LLM2GcznWFLHS40RpqGm5N58il5MgKyT3XbfMw0r3jgbpGRcyq24rtr3YrK8Bbd4DaJTO7Pm00dmW3rNlq"
        : "pk_test_51ISRsnJqkGKmOFO6YXwekNU7nltG5qZLswJWgSyQnC78FFt8d5ZxveEdy9bqmVbVPMkxAjeqdc17Wy7sCxYlAPnK00eb2dGR0l",
    ).then((res) => {
      setStripePromise(res);
    });
  }, []);

  useEffect(() => {
    if (stripe_customer?.id) {
      getCustomerPaymentMethods(
        {
          stripeCustomerId: stripe_customer.id,
        },
        (err) => {
          if (err) {
            alert(err);
          }
        },
      );
    }
  }, [stripe_customer]);

  useEffect(() => {
    if (team?.team && team?.stripeCustomer) {
      const currentSub = team.stripeCustomer.subscriptions.data.find(
        ({ id }) => id === team.team.stripeSubscription.split("%")[1],
      );
      if (currentSub) {
        const leadCapturePrice = currentSub.items.data.find(({ metadata }) => metadata.leadsQty);
        if (leadCapturePrice) {
          setLeadsNumber(Number(leadCapturePrice.metadata.leadsQty));
        }
      }
    }
  }, [team?.team]);

  return (
    <div class="flex-container">
      {!!stripePromise && (
        <Elements stripe={stripePromise} options={stripeOptions}>
          <StripeAddPaymentModal
            stripe={stripePromise}
            stripeCustomerId={stripe_customer?.id}
            open={openStripePaymentModal}
            closeModal={onCloseStripePaymentModal}
          ></StripeAddPaymentModal>
        </Elements>
      )}

      {postDemoTeamCreated ? (
        <div class="column">
          <h3>Team Created Successfully</h3>
          <DemoTeamSummary
            companyName={companyName}
            numberSeats={numberSeats}
            priceId={priceId}
            filteredPriceConfig={filteredPriceConfig}
            discountPercent={discountPercent}
            isPriceMonthly={isPriceMonthly}
            isCustomPricing={isCustomPricing}
            pricePerSeatYearlyDollars={pricePerSeatDollars}
            multiYearSub={multiYearSub}
            multiYearSubYears={multiYearSubYears}
            offerFreeTrial={offerFreeTrial}
            trialDate={trialDate}
          />
          <div style={{ paddingTop: "1rem", display: "flex", gap: "0.75rem" }}>
            <CustomButton style={{ backgroundColor: "#1881e3" }} onClick={handleDownloadQuote}>
              {isLoading ? <CircularProgress color="secondary" size={15} /> : "Download Quote"}
            </CustomButton>
            <CustomButton onClick={setDemoFlowDone}>Complete</CustomButton>
          </div>
        </div>
      ) : (
        <>
          <div class="column">
            <h3>{postDemoFlowUpgrade ? "Change Team Pricing" : "Set New Team Pricing"}</h3>
            <div className="stripeCustomerContainer">
              <div>
                <>
                  <TextField
                    required="true"
                    variant="outlined"
                    id="companyName"
                    value={companyName}
                    className={classes.extendedTextField}
                    label="Company Name"
                    onChange={(e) => setCompanyName(e.target.value)}
                    disabled={isLoading || isLoadingPreview}
                  />
                  <br />
                </>
                <hr />
                <h4>Plan Details</h4>
                <form autoComplete="off" onSubmit={handleSubmit}>
                  <FormGroup row>
                    <FormControl className={classes.formControl}>
                      <InputLabel id="demo-simple-select-label">Plan*</InputLabel>
                      <Select
                        value={postDemoFlowStoredpriceId || priceId}
                        onChange={(e) => setPriceId(e.target.value)}
                        disabled={isLoading}
                        required={true}
                      >
                        {filteredPriceConfig
                          && filteredPriceConfig.map((config) => (
                            <MenuItem value={config.priceId}>
                              {config.title} - {config.code} - {config.period}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </FormGroup>
                  {!chargeAutomatically && !isPriceMonthly && !isInvoiceMode && (
                    <>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={offerFreeTrial}
                              onChange={(e) => setOfferFreeTrial(e.target.checked)}
                              name="offerFreeTrial"
                              inputProps={{ "aria-label": "secondary checkbox" }}
                              disabled={isLoading || isInvoiceMode}
                            />
                          }
                          label={"Offer Free Trial"}
                        />
                        <br />
                      </FormGroup>
                      {offerFreeTrial && (
                        <>
                          <div className="textInputContainer">
                            <FormControl>
                              <FormLabel>Trial End Date</FormLabel>
                              <TextField
                                variant="outlined"
                                required="true"
                                onChange={(e) => {
                                  if (e.target.value.includes(" ")) return;
                                  setTrialDate(e.target.value);
                                }}
                                value={trialDate}
                                type="date"
                              />
                            </FormControl>
                          </div>
                          {validationErrors.trialDate && (
                            <p style={{ color: "red" }}>{validationErrors.trialDate}</p>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <hr />
                  <h4>Pricing Details</h4>
                  {priceId === "new_billing_price" && (
                    <>
                      <FormGroup row>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label">Leads Bucket*</InputLabel>
                          <Select
                            value={leadsNumber}
                            onChange={(e) => {
                              if (e.target.value === 50001) {
                                setLeadsNumberInput(50001);
                              }
                              setLeadsNumber(e.target.value);
                            }}
                            disabled={isLoading}
                            required={true}
                          >
                            {leadCaptureTierOptions.map(({ label, value }) => (
                              <MenuItem value={value}>{label}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {leadsNumber === 50001 && (
                          <TextField
                            required="true"
                            variant="outlined"
                            value={leadsNumberInput}
                            type="number"
                            label={"Number of Leads"}
                            onChange={(e) => setLeadsNumberInput(parseInt(e.target.value, 10))}
                            onBlur={(e) => {
                              const value = parseInt(e.target.value, 10);
                              if (value < 50001) {
                                setLeadsNumber(
                                  leadCaptureTierOptions.findLast((item, i) => item.value <= value)
                                    ?.value,
                                );
                              }
                            }}
                            disabled={isLoading || isLoadingPreview}
                            style={{ marginLeft: 10 }}
                          />
                        )}
                      </FormGroup>
                      <br />
                    </>
                  )}

                  {(!isPriceMonthly || (isPriceMonthly && !offerFreeTrial)) && (
                    <>
                      <TextField
                        required="true"
                        variant="outlined"
                        value={numberSeats}
                        type="number"
                        label={"Number of Members"}
                        onChange={(e) => {
                          const num = Number.parseInt(e.target.value, 10);
                          setNumberSeats(num);
                        }}
                        disabled={isLoading || isLoadingPreview}
                      />
                      {validationErrors.numberSeats && (
                        <p style={{ color: "red", magin: 0 }}>{validationErrors.numberSeats}</p>
                      )}
                    </>
                  )}

                  {isPriceMonthly ? (
                    <p>Custom Pricing not available for monthlies</p>
                  ) : (
                    <>
                      <FormGroup row style={{ alignItems: "center", marginTop: 10 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isCustomPricing}
                              onChange={switchPricingMode}
                              name="pricingMode"
                              inputProps={{ "aria-label": "secondary checkbox" }}
                              disabled={isLoading}
                            />
                          }
                          label={"Custom Pricing"}
                        />

                        <ToolTip desc="Check the custom pricing option you need to enable" />
                      </FormGroup>

                      {isCustomPricing && (
                        <div style={{ marginTop: 10 }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!pricePerSeatDollarsChecked}
                                onChange={(e) => {
                                  setPricePerSeatDollarsChecked(e.target.checked);
                                }}
                              />
                            }
                            label=""
                          />
                          <TextField
                            variant="outlined"
                            required
                            value={pricePerSeatDollars ?? ""}
                            type="number"
                            label={"Price Per Seat Per Year"}
                            onChange={(e) => setPricePerSeatDollars(parseFloat(e.target.value, 10))}
                            disabled={
                              isLoading
                              || isLoadingPreview
                              || pricePerSeatDollars === null
                              || !pricePerSeatDollarsChecked
                            }
                          />
                          <br />
                          <br />
                        </div>
                      )}

                      {isCustomPricing && priceId === "new_billing_price" && (
                        <div>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={!!customBucketDiscountChecked}
                                onChange={(e) => setCustomBucketDiscountChecked(e.target.checked)}
                              />
                            }
                            label=""
                          />
                          <TextField
                            variant="outlined"
                            required
                            value={customBucketDiscount ?? ""}
                            type="number"
                            label={"Bucket Discount %"}
                            onChange={(e) => setCustomBucketDiscount(parseFloat(e.target.value, 10))
                            }
                            disabled={
                              isLoading
                              || isLoadingPreview
                              || customBucketDiscount === null
                              || !customBucketDiscountChecked
                            }
                          />
                          <br />
                        </div>
                      )}
                    </>
                  )}

                  {isPriceMonthly ? (
                    <>
                      <p>Multi-Year subs are not available for monthlies</p>
                    </>
                  ) : (
                    <>
                      <FormGroup row>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={multiYearSub}
                              onChange={(e) => setMultiYearSub(e.target.checked)}
                              name="multiYearSub"
                              inputProps={{ "aria-label": "secondary checkbox" }}
                              disabled={isLoading}
                            />
                          }
                          label={"Multi Year Sub"}
                        />
                        <br />
                        <br />
                      </FormGroup>
                      {multiYearSub && (
                        <div>
                          <TextField
                            variant="outlined"
                            required="true"
                            value={multiYearSubYears}
                            type="number"
                            label={"Years"}
                            onChange={(e) => {
                              const years = Number.parseInt(e.target.value, 10);
                              setMultiYearSubYears(years);
                            }}
                            disabled={isLoading || isLoadingPreview}
                          />
                          {validationErrors.multiYearSubYears && (
                            <p style={{ color: "red" }}>{validationErrors.multiYearSubYears}</p>
                          )}
                        </div>
                      )}
                    </>
                  )}
                  <br />
                </form>
                <div
                  style={{ backgroundColor: "#1881e3" }}
                  className="buttonContainer"
                  onClick={isLoading || isLoadingPreview ? null : baseSubmit}
                >
                  {isLoadingPreview ? (
                    <CircularProgress color="secondary" size={15} />
                  ) : (
                    "Preview Pricing"
                  )}
                </div>
                <hr />
                <h4 style={{ marginBottom: 0 }}>Billing Details</h4>
                <div style={{ display: "flex", gap: "2rem" }}>
                  <FormGroup row>
                    <SelectField
                      label="Country / Region"
                      name="country"
                      dataset={countries}
                      initialValue={addressInfo.country}
                      width={448}
                      onChange={handleSelectCountry}
                      styles={{ root: { width: 200 } }}
                    />
                  </FormGroup>
                  {addressInfo.country === "US" && (
                    <FormGroup row>
                      <SelectField
                        label="State"
                        name="state"
                        dataset={states}
                        initialValue={addressInfo.state}
                        placeholder="Choose a state"
                        width={448}
                        onChange={handleSelectState}
                        styles={{ root: { width: 200 } }}
                      />
                    </FormGroup>
                  )}
                </div>
                <div>
                  <FormGroup row>
                    <TextField
                      variant="outlined"
                      value={addressInfo.addressLine}
                      className={classes.extendedTextField}
                      type="text"
                      label={"Address Line"}
                      onChange={(e) => handleAddressChange("addressLine", e.target.value)}
                      disabled={isLoading || isLoadingPreview}
                    />
                  </FormGroup>
                  <br />
                  <FormGroup row>
                    <TextField
                      variant="outlined"
                      value={addressInfo.city}
                      className={classes.extendedTextField}
                      type="text"
                      label={"City"}
                      onChange={(e) => handleAddressChange("city", e.target.value)}
                      disabled={isLoading || isLoadingPreview}
                    />
                  </FormGroup>
                  <br />

                  <FormGroup row>
                    <TextField
                      variant="outlined"
                      value={addressInfo.zipcode}
                      className={classes.extendedTextField}
                      type="number"
                      required={addressInfo.country === "US"}
                      label="Zip Code"
                      onChange={(e) => handleAddressChange("zipcode", e.target.value)}
                      disabled={isLoading || isLoadingPreview}
                    />
                  </FormGroup>
                </div>
                <div className="textInputContainer">
                  <FormControl>
                    <FormLabel>Quote/Invoice Due Date</FormLabel>
                    <TextField
                      variant="outlined"
                      required="true"
                      onChange={(e) => {
                        if (e.target.value.includes(" ")) return;
                        setDueDate(e.target.value);
                      }}
                      value={dueDate}
                      type="date"
                    />
                  </FormControl>
                </div>
                {validationErrors.dueDate && (
                  <p style={{ color: "red" }}>{validationErrors.dueDate}</p>
                )}
                <FormGroup>
                  <FormLabel>Custom Memo (Optional)</FormLabel>
                  <TextareaAutosize
                    variant="outlined"
                    value={customMemo}
                    type="text"
                    minRows={4}
                    style={{
                      width: "100%",
                      fontSize: 15,
                      font: "inherit",
                      padding: "0.5rem",
                      margin: "0.5rem 0",
                    }}
                    onChange={(e) => {
                      if (e.target.value.length <= 500) {
                        setCustomMemo(String(e.target.value));
                      }
                    }}
                    disabled={isLoading}
                  />
                </FormGroup>
                <br />
                {stripe_customer_payment_methods?.length > 0 ? (
                  <p>Payment method on file</p>
                ) : (
                  <>
                    {stripe_customer?.id && (
                      <>
                        <CustomButton
                          onClick={isLoading || isLoadingPreview ? null : doOpenStripePaymentModal}
                        >
                          Add Payment Method
                        </CustomButton>

                        <br />
                      </>
                    )}
                  </>
                )}
                <br />
                <hr />
                {(stripe_customer?.subscriptions?.data?.length || 0) < 1 && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isInvoiceMode}
                            onChange={(e) => setIsInvioceMode(e.target.checked)}
                            name="invoice-mode"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            disabled={isLoading}
                          />
                        }
                        label={"Invoice Mode"}
                      />
                      <br />
                      <br />
                      <br />
                    </FormGroup>

                    <ToolTip desc="When turned on, a subscription and invoice will be generated instead of a quote." />
                  </div>
                )}
                <div>
                  {isInvoiceMode
                    && !offerFreeTrial
                    && (stripe_customer_payment_methods?.length || 0) > 0 && (
                    <FormGroup row>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={chargeAutomatically}
                            onChange={(e) => setChargeAutomatically(e.target.checked)}
                            name="chargeAutomatically"
                            inputProps={{ "aria-label": "secondary checkbox" }}
                            disabled={isLoading}
                          />
                        }
                        label={"Charge Automatically"}
                      />
                      <br />
                    </FormGroup>
                  )}
                </div>
                <div
                  style={{
                    paddingBottom: "1rem",
                    display: "flex",
                    gap: "0.75rem",
                  }}
                ></div>
                <CustomButton
                  onClick={isLoading || isLoadingPreview ? null : doCreate}
                  disabled={
                    Object.values(validationErrors).find(((val) => val))
                    || isLoading
                    || !companyName
                    || Number.isNaN(numberSeats)
                    || Number.isNaN(discountPercent)
                    || !dueDate
                    || !priceId
                    || (isCustomPricing && Number.isNaN(pricePerSeatDollars))
                    || (multiYearSub && Number.isNaN(multiYearSubYears))
                    || (addressInfo.country === "US" && !addressInfo.zipcode)
                  }
                >
                  {isLoading ? (
                    <CircularProgress color="secondary" size={15} />
                  ) : postDemoFlowUpgrade ? (
                    "Update Team"
                  ) : (
                    "Create Team"
                  )}
                </CustomButton>
              </div>
              <br />
            </div>
          </div>
          <div class="column">
            <h3>Pricing Preview</h3>
            {(() => {
              if (isLoadingPreview) {
                return <CircularProgress />;
              }

              if (stripe_subscription_preview) {
                return (
                  <div>
                    <table>
                      <tr>
                        <th>#</th>
                        <th>Max Members</th>
                        <th>Price Per Member</th>
                        <th>Flat Amount</th>
                      </tr>

                      {stripe_subscription_preview
                        && stripe_subscription_preview.tiers.map((tier, index) => (
                          <tr>
                            <td>#{index + 1}</td>
                            <td>{tier.up_to}</td>
                            <td>{tier.unit_amount ? `$${tier.unit_amount / 100}` : ""}</td>
                            <td>{tier.flat_amount ? `$${tier.flat_amount / 100}` : ""}</td>
                          </tr>
                        ))}
                    </table>
                    <p>
                      Estimated Initial Invoice Value: $
                      {stripe_subscription_preview
                      && stripe_subscription_preview.tiers
                      && stripe_subscription_preview.tiers[0]
                      && stripe_subscription_preview.tiers[0].flat_amount
                        ? stripe_subscription_preview.tiers[0].flat_amount / 100
                        : ""}
                    </p>
                  </div>
                );
              }

              if (stripe_subscription_preview_new_billing) {
                return (
                  <dl className="grid grid-cols-2 gap-2">
                    <dt>Seats</dt>
                    <dd>${stripe_subscription_preview_new_billing.seatsTotal?.toFixed(2)}</dd>

                    <dt>Leads</dt>
                    <dd>${stripe_subscription_preview_new_billing.leadsTotal?.toFixed(2)}</dd>

                    <dt>Subtotal</dt>
                    <dd>${stripe_subscription_preview_new_billing.subTotal?.toFixed(2)}</dd>

                    {stripe_subscription_preview_new_billing.discountPercent > 0 && (
                      <>
                        <dt>Leads Discount</dt>
                        <dd>
                          {stripe_subscription_preview_new_billing.discountPercent}% ($
                          {(
                            stripe_subscription_preview_new_billing.leadsTotal
                            * (stripe_subscription_preview_new_billing.discountPercent / 100)
                          ).toFixed(2)}
                          )
                        </dd>
                      </>
                    )}

                    <dt>Total</dt>
                    <dd>${stripe_subscription_preview_new_billing.total?.toFixed(2)}</dd>
                  </dl>
                );
              }
            })()}
          </div>
        </>
      )}
    </div>
  );
};

export default InternalDemoTeamForm;
