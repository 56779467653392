import { CircularProgress } from "@material-ui/core";
import {
  useContext, useEffect, useState, useRef,
} from "react";
import MainContext from "./context/main/mainContext";

const QuoteList = () => {
  const {
    getDemoTeams,
    getTeamById,
    getPriceConfig,
    AEDemoTeams,
    downloadQuote,
    changePlan,
    price_config,
    team,
  } = useContext(MainContext);

  const [loadingState, setLoadingState] = useState({
    isLoading: false,
    isLazyLoading: false,
  });

  const [totalRecords, setTotalRecords] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [searchOptions, setSearchOptions] = useState({
    search: "",
    isMine: false,
    limit: 25,
  });

  const observer = useRef(null);
  const lastTeamRef = useRef(null);
  const listContainerRef = useRef(null);

  useEffect(() => {
    (async () => {
      setLoadingState((prev) => ({ ...prev, isLoading: true }));
      await getDemoTeams(searchOptions);
      setLoadingState((prev) => ({ ...prev, isLoading: false }));
    })();
  }, []);

  useEffect(() => {
    if (!lastTeamRef.current || loadingState.isLoading || loadingState.isLazyLoading || !hasMore) return;

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setLoadingState((prev) => ({ ...prev, isLazyLoading: true }));
        setSearchOptions((prev) => {
          const newLimit = prev.limit + 50;
          getDemoTeams({ ...prev, limit: newLimit }).then((teams) => {
            setLoadingState((prev) => ({ ...prev, isLazyLoading: false }));
            if (totalRecords === teams.length) {
              setHasMore(false);
            } else {
              setTotalRecords(teams.length);
            }
          });

          return { ...prev, limit: newLimit };
        });
      }
    });

    observer.current.observe(lastTeamRef.current);
    return () => observer.current.disconnect();
  }, [AEDemoTeams, loadingState]);

  // Handle quote download
  const handleDownloadQuote = async (event, team) => {
    event.preventDefault();
    setLoadingState((prev) => ({ ...prev, isLoading: true }));
    const data = {
      teamId: team.teamId,
      includeCheckout: true,
      companyName: team.companyName,
    };

    await downloadQuote(data, (err) => {
      if (err) {
        alert(`Error downloading quote: ${err}`);
      }
    });

    setLoadingState((prev) => ({ ...prev, isLoading: false }));
  };

  useEffect(() => {
    if (team && price_config) {
      changePlan();
    }
  }, [team?.team?.id, price_config]);

  // Handle search form submission
  const handleSubmitSearch = async (e) => {
    e.preventDefault();
    setLoadingState((prev) => ({ ...prev, isLoading: true }));
    setHasMore(true);
    const updatedSearch = { ...searchOptions, limit: 25 };

    if (listContainerRef.current) {
      listContainerRef.current.scrollTop = 0;
    }
    setSearchOptions(updatedSearch);
    await getDemoTeams(updatedSearch);
    setLoadingState((prev) => ({ ...prev, isLoading: false }));
  };

  return (
    <div className="flex h-full justify-between">
      <div className="pt-8 px-5 border border-r-gray-500">
        <form onSubmit={handleSubmitSearch} className="flex flex-col gap-2 justify-center">
          <div className="flex gap-3 items-center">
            <label className="" htmlFor="search">
              Search
            </label>
            <input
              onChange={(e) => setSearchOptions({ ...searchOptions, search: e.target.value })}
              id="search"
              className="px-2 py-1 rounded-md bg-gray-50 border border-gray-500"
              type="text"
              name="email"
              placeholder="Email or Team Id"
              value={searchOptions.search}
            />
          </div>
          <div className="flex gap-3">
            <label htmlFor="isMine">Only Mine</label>
            <input
              type="checkbox"
              name="isMine"
              className="checkbox"
              checked={searchOptions.isMine}
              onChange={(e) => setSearchOptions({ ...searchOptions, isMine: e.target.checked })}
            />
          </div>

          <button className="btn-primary" type="submit">
            Find
          </button>
        </form>
      </div>
      <div
        ref={listContainerRef}
        className="w-5/6 p-5 overflow-auto h-[95vh]"
      >
        {loadingState.isLoading && (
          <div className="h-full w-full flex justify-center items-center">
            <CircularProgress />
          </div>
        )}
        {!loadingState.isLoading
          && AEDemoTeams.map((demoTeam, index) => {
            const keyVals = Object.entries(demoTeam);
            return (
              <div
                key={demoTeam.id}
                className="border-b border-gray-400 py-3"
                ref={index === AEDemoTeams.length - 1 ? lastTeamRef : null}
              >
                <div className="flex gap-3 mb-5">
                  <p className="font-bold text-lg m-0">Team {demoTeam.teamId}</p>
                  {demoTeam.stripeInvoiceLink && (
                    <a
                      className="btn-secondary"
                      href={demoTeam.stripeInvoiceLink}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Open Invoice
                    </a>
                  )}
                  {!demoTeam.stripeInvoiceLink && (
                    <button
                      className="btn-secondary cursor-pointer"
                      onClick={(e) => handleDownloadQuote(e, demoTeam)}
                    >
                      Download Quote
                    </button>
                  )}
                  <button
                    className="btn-secondary cursor-pointer"
                    onClick={async (e) => {
                      e.preventDefault();
                      setLoadingState((prev) => ({ ...prev, isLoading: true }));
                      await getTeamById(demoTeam?.teamId, (err) => {
                        if (err) {
                          alert(err);
                        }
                        setLoadingState((prev) => ({ ...prev, isLoading: false }));
                      });
                      await getPriceConfig((err) => {
                        if (err) {
                          alert(err);
                        }
                        setLoadingState((prev) => ({ ...prev, isLoading: false }));
                      });
                    }}
                  >
                    Change Plan
                  </button>
                </div>
                <div className="grid grid-cols-2 gap-4">
                  {keyVals.map(([key, val]) => {
                    if (key === "stripeInvoiceLink") return null;
                    return (
                      <div key={key} className="flex">
                        <p className="font-semibold w-1/2 m-0">{`${key}`}:</p>
                        <p className="w-1/2 break-words m-0">{`${val}`}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        {loadingState.isLazyLoading && hasMore && (
          <div className="w-full flex justify-center items-center py-3">
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  );
};

export default QuoteList;
