import { TextField } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import FormGroup from "@material-ui/core/FormGroup";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles } from "@material-ui/core/styles";
import MainContext from "./context/main/mainContext";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

const CheckoutLinks = () => {
  const mainContext = useContext(MainContext);
  const classes = useStyles();
  const {
    user, coupon_config, getPriceConfig, snackbarAction, isProd,
  } = mainContext;

  const [memberCount, setMemberCount] = useState(10);
  const [cardCount, setCardCount] = useState(10);
  const [email, setEmail] = useState("");
  const [account, setAccount] = useState("");

  const [discountId, setDiscountId] = useState("");

  // Get price config
  useEffect(() => {
    if (!coupon_config) {
      getPriceConfig((err) => {
        if (err) {
          alert(err);
        }
      });
    }
  }, []);

  useEffect(() => {
    document.title = "Popl Internal Dash - Checkout Links";
  });

  const [links, setLinks] = useState({
    share: "",
    connect: "",
    entrust: "",
    starter: "",
    business: "",
    premium: "",
  });

  useEffect(() => {
    const emailUrlencoded = encodeURIComponent(email);
    const baseUrl = process.env.REACT_APP_POPLBE_URL;
    const commonParams = `rep=${user.id}&members=${memberCount}&email=${emailUrlencoded}&account=${account}`;
    const cardParam = `&cards=${cardCount}`;
    const discountParam = discountId ? `&discount=${discountId}` : "";

    const generateLink = (plan, includeCards = false) => `${baseUrl}/internal/up/checkout?${commonParams}&plan=${plan}${includeCards ? cardParam : ""}${discountParam}`;

    const prodLinks = {
      share: generateLink("share"),
      connect: generateLink("connect", true),
      entrust: generateLink("entrust", true),
    };

    const legacyLinks = {
      starter: generateLink("starter"),
      business: generateLink("business", true),
      premium: generateLink("premium", true),
    };

    setLinks(isProd ? prodLinks : legacyLinks);
  }, [memberCount, cardCount, discountId, email, account]);

  const handleCopy = (text) => {
    const hasAllRequired = [memberCount, cardCount, email, account].every(
      (field) => field !== "" && field !== null && field !== undefined,
    );

    if (!hasAllRequired) {
      return snackbarAction({
        message: "Must fill out all required fields",
        severity: "error",
        duration: 3000,
      });
    }

    navigator.clipboard.writeText(text);
    snackbarAction({
      message: "Copied Checkout Link!",
      severity: "success",
      duration: 3000,
    });
  };

  return (
    <div className="projectContainer">
      <div className="appHeaderBio">
        <h1>Checkout Links</h1>
        <p>Use this tool to make direct checkout links.</p>
        <p>
          <b>To go digitial only, set the number of cards to 0.</b>
        </p>
      </div>
      <FormGroup>
        <div className="textInputContainer">
          <TextField
            required="true"
            variant="outlined"
            value={email}
            type="text"
            label={"SF Opportunity Email"}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="textInputContainer">
          <TextField
            required="true"
            variant="outlined"
            value={account}
            type="text"
            label={"SF Account ID"}
            onChange={(e) => setAccount(e.target.value)}
          />
        </div>
        <div className="textInputContainer">
          <TextField
            required="true"
            variant="outlined"
            value={memberCount}
            type="number"
            label={"Member Count"}
            onChange={(e) => setMemberCount(e.target.value)}
          />
        </div>
        <div className="textInputContainer">
          <TextField
            required="true"
            variant="outlined"
            value={cardCount}
            type="number"
            label={"Card Count"}
            onChange={(e) => setCardCount(e.target.value)}
          />
        </div>
      </FormGroup>
      <div className="textInputContainer">
        <FormGroup row>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Discount</InputLabel>
            <Select value={discountId} onChange={(e) => setDiscountId(e.target.value)}>
              <MenuItem value={""}>{"No discount"}</MenuItem>
              {coupon_config
                && coupon_config.map((config) => <MenuItem value={config.id}>{config.name}</MenuItem>)}
            </Select>
          </FormControl>
        </FormGroup>
      </div>
      <br />

      <hr />

      {Object.keys(links).map((key) => (
        <div key={key} className="textInputContainer flex">
          <TextField
            variant="outlined"
            label={key}
            value={links[key]}
            style={{ width: 1000 }}
            disabled={true}
          />
          <button
            onClick={() => handleCopy(links[key])}
            className="btn-secondary rounded-l-none w-12"
          >
            Copy
          </button>
        </div>
      ))}
      <hr />
    </div>
  );
};

export default CheckoutLinks;
