import React, { useContext, useEffect, useState } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextField from "@material-ui/core/TextField";
import MainContext from "./context/main/mainContext";
import UserModal from "./UserModal";
import TeamModal from "./TeamModal";
import planLookup from "./utilities/planLookup";

const Search = () => {
  document.title = "Popl Internal Dash - Search";

  const mainContext = useContext(MainContext);
  const {
    searchMembersAndTeams,
    resetState,
    search_results,
    getPriceConfig,
    price_config,
    inputLookupText,
    onChangeInputLookupText,
  } = mainContext;

  const [isLoading, setIsLoading] = useState(false);
  const [inputLookupErrorText, setInputLookupErrorText] = useState("");

  const doLookup = () => {
    // Clear out global state
    resetState();

    // Reset modal state
    setTeamId(0);
    setUserId(0);

    // Set loading to true
    setIsLoading(true);
    setInputLookupErrorText("");

    searchMembersAndTeams(inputLookupText, (err) => {
      if (err) {
        setInputLookupErrorText(`Error searching: ${err}`);
      }
      setIsLoading(false);
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    doLookup();
  };

  const [openUserModal, setOpenUserModal] = useState(false);
  const [userId, setUserId] = useState(0);

  const onClickUser = (user_id) => {
    setOpenTeamModal(false);
    setUserId(user_id);
    setOpenUserModal(true);
  };

  const onCloseUserModal = () => {
    setOpenUserModal(false);
  };

  const [openTeamModal, setOpenTeamModal] = useState(false);
  const [teamId, setTeamId] = useState(0);

  const onClickTeam = (team_id) => {
    setOpenUserModal(false);
    setTeamId(team_id);
    setOpenTeamModal(true);
  };

  const onCloseTeamModal = () => {
    setOpenTeamModal(false);
  };

  const relativeDate = (date) => {
    if (!date) return "";

    const now = new Date();
    const then = new Date(date);

    const diff = now.getTime() - then.getTime();

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days} days ago`;
    }
    if (hours > 0) {
      return `${hours} hours ago`;
    }
    if (minutes > 0) {
      return `${minutes} minutes ago`;
    }
    return "Just now";
  };

  const relativeDateLogin = (user) => {
    if (user.app_last_open && user.dashboard_last_open) {
      if (user.app_last_open > user.dashboard_last_open) {
        return relativeDate(user.app_last_open);
      }
      return relativeDate(user.dashboard_last_open);
    }
    return relativeDate(user.app_last_open || user.dashboard_last_open);
  };

  useEffect(() => {
    if (!price_config) {
      getPriceConfig(() => {});
    }
  }, []);

  const userPlanString = (user) => {
    if (user.memberTeams.length !== 0 && user.activeTeamsMember) {
      // Check if a team is paying
      const payingTeam = user.memberTeams.find((team) => team?.team?.enterprisePlan !== "");
      if (payingTeam) {
        let result = planLookup(payingTeam.team, price_config);
        return result;
      }
    }

    if (user.isPro == 2) {
      return "Pro +";
    }
    if (user.isPro == 1) {
      return "Pro";
    }
    return "Free";
  };

  return (
    <div className="projectContainer">
      <h3>Search for Members / Teams</h3>
      <div className="inputContainer">
        <form autoComplete="off" onSubmit={handleSubmit}>
          <TextField
            required
            variant="outlined"
            label="ID / Email / Slug / Hash / DeviceID"
            style={{ width: "300px" }}
            onChange={(e) => onChangeInputLookupText(e.target.value.trim())}
            disabled={isLoading}
            error={inputLookupErrorText !== ""}
            helperText={inputLookupErrorText}
            autoComplete="off"
          />
        </form>
        <br />
        <div className="buttonContainer" onClick={isLoading ? null : doLookup}>
          {isLoading ? <CircularProgress color="secondary" size={15} /> : "Search"}
        </div>
      </div>
      <br />
      {openUserModal && (
        <UserModal
          openTeamFn={onClickTeam}
          userId={userId}
          open={openUserModal}
          closeModal={onCloseUserModal}
        ></UserModal>
      )}
      {openTeamModal && (
        <TeamModal
          openUserFn={onClickUser}
          teamId={teamId}
          open={openTeamModal}
          closeModal={onCloseTeamModal}
        ></TeamModal>
      )}
      <div class="flex-container">
        <div class="column">
          <h3>Members ({search_results?.results?.members?.length || 0})</h3>
          {isLoading ? (
            <CircularProgress />
          ) : !search_results || !search_results.results || !search_results.results.members ? (
            <p>No results</p>
          ) : (
            <div>
              <table>
                <tr>
                  <th>ID</th>
                  {/* <th>UID</th> */}
                  <th>Email</th>
                  <th>Created Date</th>
                  <th>Last Login</th>
                  <th>Plan</th>
                  <th>Hash / Slug</th>
                  <th></th>
                </tr>
                {search_results.results.members.map((user, index) => (
                  <tr onClick={(e) => onClickUser(user.id)}>
                    <td>{user.id}</td>
                    {/* <td>{tier.uid}</td> */}
                    <td>{user.email}</td>
                    <td>{relativeDate(user.registeredDate)}</td>
                    <td>{relativeDateLogin(user)}</td>
                    <td>{userPlanString(user)}</td>
                    <td>
                      {user.hash} / {user.slug}
                    </td>
                    <td>
                      <div className="buttonContainerMini">Open</div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          )}
        </div>
        <div class="column">
          <h3>Teams ({search_results?.results?.teams?.length || 0})</h3>
          {isLoading ? (
            <CircularProgress />
          ) : !search_results || !search_results.results || !search_results.results.teams ? (
            <p>No results</p>
          ) : (
            <div>
              <table>
                <tr>
                  <th>ID</th>
                  {/* <th>UID</th> */}
                  <th>Email</th>
                  <th>Created Date</th>
                  <th>Plan</th>
                  <th></th>
                </tr>
                {search_results.results.teams.map((team, index) => (
                  <tr onClick={(e) => onClickTeam(team.id)}>
                    <td>{team.id}</td>
                    {/* <td>{tier.uid}</td> */}
                    <td>{team.email}</td>
                    <td>{relativeDate(team.createdAt)}</td>
                    <td>{planLookup(team, price_config)}</td>
                    <td>
                      <div className="buttonContainerMini">Open</div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
