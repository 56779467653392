import { useContext, useEffect, useState } from "react";
import MainContext from "../context/main/mainContext";

export default function useCommonMemberAndTeamAction(itemType) {
  const mainContext = useContext(MainContext);
  const {
    member, updateMember, team, updateTeam,
  } = mainContext;

  const [isTextFollowUp, setIsTextFollowUp] = useState(false);
  const [isEmailFollowUp, setIsEmailFollowUp] = useState(false);
  const [isSendWeeklyReminder, setIsSendWeeklyReminder] = useState(false);
  const [email, setEmail] = useState("");
  const [editEmailOpen, setEditEmailOpen] = useState(false);

  const [isCommonActionLoading, setIsCommonActionLoading] = useState(false);

  useEffect(() => {
    if (itemType === "team") {
      setEmail(team?.team?.email);
      setIsSendWeeklyReminder(team?.team?.sendWeeklyInviteReminders);
      setIsTextFollowUp(team?.team?.textFollowUp);
      setIsEmailFollowUp(team?.team?.emailFollowUp);
    }
  }, [team?.team]);

  useEffect(() => {
    if (itemType === "member") {
      setEmail(member?.member?.email);
      setIsTextFollowUp(member?.member?.textFollowUp);
      setIsEmailFollowUp(member?.member?.emailFollowUp);
    }
  }, [member?.member]);

  const handleToggle = async (updateFunc, id, param) => {
    setIsCommonActionLoading(true);
    const updatedMember = await updateFunc(id, param);
    setIsCommonActionLoading(false);

    let cb = null;

    switch (true) {
    case "teamOwnerEmain" in param || "emain" in param:
      cb = setEmail;
      break;
    case "textFollowUp" in param:
      cb = setIsTextFollowUp;
      break;
    case "emailFollowUp" in param:
      cb = setIsEmailFollowUp;
      break;
    case "sendWeeklyInviteReminders" in param:
      cb = setIsSendWeeklyReminder;
      break;
    default:
      cb = () => {};
    }

    cb(updatedMember[Object.keys(param)[0]]);
  };

  const handleUpdateMember = (param) => handleToggle(updateMember, member?.member?.id, param);
  const handleUpdateTeam = (param) => handleToggle(updateTeam, team?.team?.id, param);

  const handleSetEmailOpen = (state) => {
    setEmail(email);
    setEditEmailOpen(state);
  };

  const commonItems = {
    email,
    handleEmailChange: setEmail,
    isTextFollowUp,
    isEmailFollowUp,
    isCommonActionLoading,
    editEmailOpen,
    handleSetEmailOpen,
  };

  const teamItems = {
    ...commonItems,
    handleUpdateTeam,
    isSendWeeklyReminder,
  };

  const memberItems = {
    ...commonItems,
    handleUpdateMember,
  };

  const items = {
    member: memberItems,
    team: teamItems,
  };

  return items[itemType];
}
